export const tab = () => {
  var tabs = document.querySelectorAll(".services-list");
  var titles = document.querySelectorAll(".realestate_titles");

  function toggleTab(index) {
    tabs.forEach(function (tab, i) {
      if (i === index) {
        var isActive = tab.classList.contains("active");

        if (!isActive) {
          tabs.forEach(function (t) {
            t.classList.remove("active");
          });
          titles.forEach(function (title) {
            title.classList.remove("active");
          });

          tab.classList.add("active");
          titles[i].classList.add("active");
        }
      }
    });
  }

  titles.forEach(function (title, index) {
    title.addEventListener("click", function () {
      toggleTab(index);
    });
  });

  toggleTab(0);
};
