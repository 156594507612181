import { burger } from "./modules/header/burger.js";
import { slider } from "./modules/slider.js";
import { tab } from "./modules/tab.js";
import { modals } from "./modules/modals.js";
import { popup } from "./modules/popup.js";
import { departament } from "./modules/departament.js";
document.addEventListener("DOMContentLoaded", function (event) {
  modals();
  popup();
  tab();
  departament();
  burger();
  slider();
});
