export const departament = () => {
  jQuery(document).ready(function () {
    jQuery(".buttons_departament").click(function (e) {
      e.preventDefault();
      var popupId = jQuery(this).attr("data-popup");
      jQuery("#" + popupId).removeClass("hidden");
      jQuery("body").addClass("departaments");
    });

    jQuery(".departament_close, .departaments .overlay").click(function (e) {
      e.preventDefault();
      closePopups();
    });

    jQuery(document).keyup(function (e) {
      if (e.key === "Escape") {
        closePopups();
      }
    });
    jQuery(".departaments .overlay, .departaments_popup").click(function (e) {
      if (e.target === this) {
        closePopups();
      }
    });

    function closePopups() {
      jQuery(".departaments_content_text").addClass("hidden");
      jQuery("body").removeClass("departaments");
    }
  });
};
